export default {
    state: {
      sweepstakesNumber: {
        params: {
            name: 'Sorteio de Números',
            maxDraw: 1,
            rangeInitial: 1,
            rangeEnd: 100,
            repeatNumber: false,
            interval: 1,
            sweepstakeUnique: '1'
        },
        numbers: [],
        drawn: []
      }
    },
    getters: {
      getAllParams: (state) => state.sweepstakesNumber,
    },
    mutations: {
        setParams: function (state, data) {
            state.sweepstakesNumber = data
        }
    },
    actions: {
        setParams: function ({ commit }, params) {
            commit('setParams', params)
        }
    }
  }
  