export default {
  state: {
    sweepstakes: [
      {
        name: 'Sorteio de números',
        description: 'Sortear 1 ou mais numeros atelatoriamente',
        path: '/sorteionumeros'
      },
      {
        name: 'Sorteio lista',
      }
    ]
  },
  getters: {
    getSweepstakes: (state) => state.sweepstakes,
  },
  mutations: {
  },
  actions: {
  }
}
