import { createStore } from 'vuex'
import sweepsatkesStore from './sweepsatkesStore.js'
import sweepstakesNumberStore from './sweepstakes/sweepstakesNumberStore.js'

export default createStore({
  modules: {
    sweepsatkesStore,
    sweepstakesNumberStore
  }
})
