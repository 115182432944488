<template>
  <div class="home">
    {{teste}}
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {
  },
  data() {
    return {
      teste: 'TESTE'
    }
  }
}
</script>
