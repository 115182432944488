<template>
  <div class="home">
    <template v-for="(sweepstakes, key) in getSweepstakes" :key="key">
      <SweepstakesCardComponentVue :sweepstakes="sweepstakes"></SweepstakesCardComponentVue>
    </template>
  </div>
</template>

<script>
import SweepstakesCardComponentVue from '@/components/sweepstakes/SweepstakesCardComponent.vue'
// @ is an alias to /src
import { mapGetters } from 'vuex'

export default {
  name: 'SweepstakesView',
  components: {
    SweepstakesCardComponentVue
  },
  computed: {
    ...mapGetters(['getSweepstakes'])
  },
  mounted () {
    console.log(this.getSweepstakes)
  },
  data() {
    return {

    }
  },
  methods: {
    // ...mapActions(['set'])
  }
}
</script>
