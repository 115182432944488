<template>
  <div class="sweepstakesnumber-container">
    <div class="title">
      <h1> {{  getAllParams.params.name }}</h1>
    </div>
    <div class="sweepstakes-number">
      <div class="sweepstakes-params" v-if="!paramsDefined">
        <div class="params">
          <span> Sortear </span>
          <input type="text" v-model="maxDraw"/>
          <span> número(s) de </span>
          <input type="text" v-model="rangeInitial"/>
          <span> à </span>
          <input type="text" v-model="rangeEnd"/>
        </div>
        <div class="checkbox-options">
          <div class="option">
            <div></div>
            <span>Exibir sorteado(s) após: <input type="text" name="seconds" v-model="interval"> segundos</span>
          </div>
          <div class="option">
            <input type="checkbox" name="repeat-number" v-model="repeatNumber"/>
            <label for="repeat-number">Permite sortear o mesmo numero 2x ou mais?</label>
          </div>
          <div class="option">
            <input type="radio" name="show-result" id="show-result-1" v-model="sweepstakeUnique" value="1" checked/>
            <label for="show-result">Exibir todos os sorteados de uma vez</label>
          </div>
          <div class="option">
            <input type="radio" name="show-result" id="show-result-2" v-model="sweepstakeUnique" value="2"/>
            <label for="show-result">Exibir sorteados automaticamente 1 a 1 após o intervalo</label>
          </div>
        </div>
      </div>
      <div class= 'sweepstakes-container' v-else>
        <div class="sweepstakes">
          <div class="card-container">
            <SweepstakesNumbersContainer></SweepstakesNumbersContainer>
          </div>
          <div class="resume-container">
            <h2>Sorteados</h2>
            <div class="drawn-container">
              <TransitionGroup name="list" tag="div">
              <div class='drawn-item' v-for="(d, key) in draws" :key="d.number" :data-index="key">
                  {{ d.number }}
                </div>
              </TransitionGroup>
          </div>
          <div v-if="drawLots">
              <span>Próximo sorteado em: {{ timeout }}</span>
          </div>
        </div>
      </div>
      <div class="buttons">
        <button @click="initDraw()" v-if="!finish && !drawLots">Iniciar Sorteio</button>
        <button @click="back()" v-if="allFinish && !drawLots">Voltar</button>
        <button @click="initDraw(true)" :class="{'disabled': drawLots}" v-if="finish && !allFinish">Sortear +1</button>
        <button @click="redefine()" v-if="finish">Redefinir e sortear novamente</button>
        <button @click="conclude()" v-if="finish" v-text="!allFinish ? 'Concluir e gerar resultados' : 'Visualizar Resultado'"></button>
      </div>
      </div>
      <div class="sweepstakesnumber-footer" v-if="!paramsDefined">
        <div class="buttons">
          <button @click="buildDraw()">Sortear</button>
          <button @click="redefineParams()">Redefinir</button>
        </div>
      </div>
    </div>
    <div class="overlay" v-if="concludeModal"></div>
    <ConcludeNumberModal @closeConcludeNumberModal="closeConcludeNumberModal" :draws="draws" v-if="concludeModal"></ConcludeNumberModal>
  </div>
</template>

<script>
import SweepstakesNumbersContainer from '@/components/sweepstakes/SweepstakesNumbersContainer.vue'
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex'
import Util from '../components/libs/Util'
import ConcludeNumberModal from '../components/modals/ConcludeNumberModal'

export default {
  name: 'SweepstakesNumberView',
  components: {
    SweepstakesNumbersContainer,
    ConcludeNumberModal
  },
  computed: {
    ...mapGetters(['getAllParams'])
  },
  data() {
    return {
      maxDraw: 0,
      rangeInitial: 0,
      rangeEnd: 0,
      interval: 0,
      repeatNumber: 0,
      sweepstakeUnique: '',
      paramsDefined: false,
      draws: [],
      timeout: 0,
      drawLots: false,
      finish: false,
      concludeModal: false,
      allFinish: false
    }
  },
  mounted() {
      this.maxDraw = this.getAllParams.params.maxDraw
      this.rangeInitial = this.getAllParams.params.rangeInitial
      this.rangeEnd = this.getAllParams.params.rangeEnd
      this.interval = this.getAllParams.params.interval
      this.repeatNumber = this.getAllParams.params.repeatNumber
      this.sweepstakeUnique = this.getAllParams.params.sweepstakeUnique
  },
  methods: {
    ...mapActions(['setParams']),
    initDraw: async function (more = false) {
      this.drawLots = true
      if (more) {
        await this.draw()
      } else {
        for (let i = 0; i < this.maxDraw; i++) {
          await this.draw()
        }
      }
      this.finish = true
      this.drawLots = false
    },
    buildNumbers: function () {
      let numbers = []
      let initial = this.rangeInitial
      if (this.rangeInitial && this.rangeEnd) {
        for (let i = initial; i <= this.rangeEnd; i++) {
          let number = {
            number: i,
            selected: false
          }
          numbers.push(number)
        }
      }
      return numbers
    },
    buildDraw: async function () {
      let allParams = this.getAllParams
      Object.keys(allParams.params).forEach(e => {
        if (this[e] !== undefined) {
          allParams.params[e] = this[e]
        }
      })

      allParams.numbers = this.buildNumbers()
      let time = 10
      for (let i = 0; i <= time; i++) {
        await Util.delay(0.001)
      }
      this.setParams(allParams)
      this.paramsDefined = true
    },
    draw: async function () {
      let numbers = []
      if (this.repeatNumber) {
          numbers = this.getAllParams.numbers
          } else {
          numbers = this.getAllParams.numbers.filter((e) => {
            return !e.selected
          })
        }
        if (numbers && numbers.length > 0) {
          if (this.interval) {
            let timeout = this.interval
            for (let i = timeout; i > 0; i --) {
              this.timeout = i
              await Util.delay(1)
            }
          }
          if (this.sweepstakeUnique === '1') {
            this.interval = 0
          }
          const randomElement = numbers[Math.floor(Math.random() * numbers.length)]
          let selected = randomElement
          selected.selected = true
          this.draws.push(selected)
          numbers.slice(numbers.indexOf(randomElement), 1)
        }
    },
    redefine: function() {
      this.draws = []
      this.buildDraw()
      this.finish = false
    },
    redefineParams: function () {
      let allParams = this.getAllParams
      allParams.params.maxDraw = 1
      allParams.params.rangeInitial = 1
      allParams.params.rangeEnd = 100
      allParams.params.repeatNumber = false
      allParams.params.interval = 1
      allParams.params.sweepstakeUnique = '1'
      allParams.numbers = []
      allParams.draw = []
      this.setParams(allParams)
      this.maxDraw = this.getAllParams.params.maxDraw
      this.rangeInitial = this.getAllParams.params.rangeInitial
      this.rangeEnd = this.getAllParams.params.rangeEnd
      this.interval = this.getAllParams.params.interval
      this.repeatNumber = this.getAllParams.params.repeatNumber
      this.sweepstakeUnique = this.getAllParams.params.sweepstakeUnique
    },
    conclude: function () {
      this.allFinish = true
      this.concludeModal = true
    },
    closeConcludeNumberModal: function () {
      this.concludeModal = false
    },
    back: function () {
      window.location.reload()
    }
  }
}
</script>
<style scoped lang="scss">
  .sweepstakesnumber-container {
    background-color: orange;
    height: calc(100vh - 90px);
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    .title {
      h1 {
        margin-bottom: 20px;
      }
    }
    .sweepstakes-number {
      width: calc(100% - 370px);
      height: calc(80vh - 100px);
      border-radius: 30px;
      background-color: white;
      display: grid;
      grid-template-rows: 3fr 1fr;
      .sweepstakes-params {
        margin: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .params {
          margin: 80px 0;
          input {
            font-size: 2ch;
            text-align: center;
            width: 40px;
            height: 40px;
            border: solid 1px rgb(141, 141, 141);
            border-radius: 10px;
          }
        }
        .checkbox-options {
          padding-bottom: 20px;
          .option {
            display: grid;
            grid-template-columns: max-content max-content;
            align-items: center;
            input[type=checkbox] {
              height: 25px;
              width: 25px;
            }
            input[type=radio] {
              height: 25px;
              width: 25px;
            }
            input[type=text] {
              text-align: center;
              border: solid 1px;
              border-radius: 5px;
              height: 20px;
              width: 30px;
              font-size: 1.8ch;
            }
            label {
              padding: 10px 10px;
            }
          }
        }
      }
      .sweepstakesnumber-footer {
        display: grid;
        align-items: end;
        .buttons {
          button {
            border: none;
            border-radius: 10px;
            width: 8rem;
            height: 3rem;
            margin: 10px;
            background-color: orange;
            color: white;
            font-size: 2ch;
          }
        }
      }
      .sweepstakes-container {
        display: grid;
        grid-template-rows: 85% 15%;
        height: 100%;
        .sweepstakes {       
          display: grid;
          grid-template-columns: 50% 50%;
          height: 100%;
          margin: 20px 40px;
        .card-container {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .resume-container {
          padding-left: 30px;
          overflow: auto;
          overflow-x: hidden;
          height: 400px;
          .drawn-container {
            // display: flex;
            // flex-direction: row;
            // flex-wrap: wrap;
            // margin: 20px;
            div {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              // margin: 20px;
              .drawn-item {
                display: flex;
                align-items: center;
                justify-content: center;
                border: solid 1px;
                width: 60px;
                height: 40px;
                background-color: green;
                color: white
              }
            }
          }
        }
        }
        .buttons {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          z-index: 1;
          background-color: white;
          border-bottom-right-radius: 30px;
          border-bottom-left-radius: 30px;
          button {
            border: none;
            border-radius: 10px;
            width: max-content;
            height: 3rem;
            margin: 10px;
            background-color: orange;
            color: white;
            font-size: 2ch;
            &.disabled {
            background-color: rgb(168, 168, 168);
            pointer-events: none;
          }
          }
        }
      }
    }
    .overlay {
      background-color: rgba(0, 0, 0, 0.678);
      width: 100%;
      height: 100vh;
      z-index: 1;
      position: absolute;
      top: 0;
      bottom: 0;
    }
  }
  @media screen and (max-width: 721px) { 
    .sweepstakesnumber-container {
      .sweepstakes-number {
        width: calc(100% - 40px);
        height: calc(80vh - 40px);
        border-radius: 30px;
        background-color: white;
        display: grid;
        grid-template-rows: 3fr 1fr;
      .sweepstakes-params {
        .params {
          margin: 40px 0;
          input {
            font-size: 2ch;
            text-align: center;
            width: 30px;
            height: 30px;
            border: solid 1px rgb(141, 141, 141);
            border-radius: 10px;
          }
          span {
            margin: 5px;
          }
        }
        .checkbox-options {
          padding-bottom: 20px;
          .option {
            display: grid;
            grid-template-columns: max-content auto;
            align-items: center;
            input[type=checkbox] {
              height: 30px;
              width: 30px;
            }
            input[type=radio] {
              height: 30px;
              width: 30px;
            }
            label {
              padding: 10px 5px;
            }
          }
        }
      }
      .sweepstakesnumber-footer {
        display: grid;
        align-items: end;
        .buttons {
          button {
            border: none;
            border-radius: 10px;
            width: 8rem;
            height: 3rem;
            margin: 10px;
            background-color: orange;
            color: white;
            font-size: 2ch;
          }
        }
      }
      .sweepstakes-container {
        // display: grid;
        // grid-template-rows: 85% 15%;
        // height: 100%;
        display: block;
        .sweepstakes {       
          display: grid;
          grid-template-columns: unset;
          grid-template-rows: auto auto;
          height: 100%;
          margin: 20px 40px;
        .card-container {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .resume-container {
          padding-left: 30px;
          overflow: auto;
          overflow-x: hidden;
          height: 400px;
          .drawn-container {
            // display: flex;
            // flex-direction: row;
            // flex-wrap: wrap;
            // margin: 20px;
            div {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              // margin: 20px;
              .drawn-item {
                display: flex;
                align-items: center;
                justify-content: center;
                border: solid 1px;
                width: 60px;
                height: 40px;
                background-color: green;
                color: white
              }
            }
          }
        }
        }
        .buttons {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          z-index: 1;
          background-color: white;
          border-bottom-right-radius: 30px;
          border-bottom-left-radius: 30px;
          button {
            border: none;
            border-radius: 10px;
            width: max-content;
            height: 3rem;
            margin: 10px;
            background-color: orange;
            color: white;
            font-size: 2ch;
            &.disabled {
            background-color: rgb(168, 168, 168);
            pointer-events: none;
          }
          }
        }
      }
    }
    }
   }
.list-enter-active,
.list-leave-active {
    transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(50px);
}
</style>
