<template>
  <div class="conclude-modal">
    <div class="modal-title">
      <button @click="closeConcludeNumberModal()" class="button-close"> X </button>
      <h2>Resultado</h2>
    </div>
    <div class="modal-body">
      <div class="sweepstake-result">
        <div class="body-title">
          <h2>Sorteados</h2>
        </div>
        <div class="draw-container">
          <div class="draw-item" v-for="(draw, key) in draws" :key="key">
            {{ draw.number }}
          </div>
        </div>
      </div>
      <div class="sweepstakes-resume">
        <div class="body-title">
          <h2>Resumo</h2>
        </div>
        <div class="draw-resume-container">
          <div class="draw-resume" v-for="(draw, key) in draws" :key="key">
            <span> {{ key + 1 }}° <span> {{ draw.number }} </span></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConcludeNumberModal',
  props: {
    draws: Array
  },
  methods: {
    closeConcludeNumberModal: function () {
      this.$emit('closeConcludeNumberModal')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .conclude-modal {
    position: absolute;
    z-index: 2;
    background-color: white;
    width: 800px;
    height: 600px;
    border-radius: 25px;
    margin: auto;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    .modal-title {
      .button-close {
        position: relative;
        border: none;
        border-radius: 30px;
        background-color: red;
        color: white;
        width: 35px;
        height: 35px;
        float: right;
        margin-top: -20px;
        margin-right: -10px;
      }
      h2 {
        font-size: 2ch;
      }
    }
    .modal-body {
      display: grid;
      grid-template-rows: 50% 50%;
      .card-container-resume {
        margin: 40px;
      }
      .sweepstake-result {
        .draw-container {
          margin: 20px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-around;
          overflow: auto;
          max-height: 200px;
          .draw-item {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 10.5rem;
            height: 3rem;
            background-color: green;
            color: white;
            font-size: 3ch;
            margin: 3px;
          }
        }
        .draw-container::-webkit-scrollbar {
          width: 5px;
          background-color: #5ef51300;
          border-radius: 5px;
          display: block;
          margin-top: 120px;
          height: 7px;
          width: 5px;
        }
        .draw-container::-webkit-scrollbar-thumb{
          border-radius: 5px;
          -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
          background-color: orange;
          width: 10px;
        }
        .draw-container::-webkit-scrollbar-track {
          /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
          border-radius: 10px;
          background-color: #D0D4CE;
          margin: 5px;
        }
      }
      .sweepstakes-resume {
        margin: 0 20px;
        .draw-resume-container {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          height: 200px;
          overflow: auto;
          .draw-resume {
            span {
              display: flex;
              justify-content: start;
              align-items: center;
              width: 100px;
              margin: 10px;
              font-weight: bold;
            }
          }
        }
        .draw-resume-container::-webkit-scrollbar {
          width: 1px;
          background-color: #5ef51300;
          border-radius: 5px;
          display: block;
          margin-top: 120px;
          height: 7px;
          width: 2px;
        }
        .draw-resume-container::-webkit-scrollbar-thumb{
          border-radius: 5px;
          -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
          background-color: orange;
          width: 5px;
        }
        .draw-resume-container::-webkit-scrollbar-track {
          /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
          border-radius: 10px;
          background-color: #D0D4CE;
          margin: 20px;
        }
      }
    }
  }
</style>
