<template>
  <div class="sweepstakes-numbers-container">
    <template v-for="(number, key) in this.getAllParams.numbers" :key="key">
      <SweepstakesNumbersComponent :number="number"></SweepstakesNumbersComponent>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SweepstakesNumbersComponent from './SweepstakesNumbersComponent.vue'

export default {
  name: 'SweepstakesCardComponent',
  components: {
    SweepstakesNumbersComponent
  },
  data() {
    return {
      numbers: []
    }
  },
  computed: {
    ...mapGetters(['getAllParams'])
  },
  mounted() {
    this.numbers = this.getAllParams.numbers
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .sweepstakes-numbers-container {
    height: max-content;
    max-height: 400px;
    overflow: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
</style>
