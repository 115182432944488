<template>
  <div class="number-container" :class="{selected: number.selected}">
    {{ number.number }}
  </div>
</template>

<script>
export default {
  name: 'SweepstakesNumbersComponent',
  props: {
    number: Object
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .number-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px;
    width: 40px;
    height: 40px;
    &.selected {
      background-color: green;
      color: white;
      border: solid 1px rgb(1, 63, 1);
    }
  }

</style>
