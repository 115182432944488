<template>
  <div class="container">
    <div class="title" @click="navigateTo(sweepstakes.path)">
      {{ sweepstakes.name }}
    </div>
    <div class="description">
      {{ sweepstakes.description }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SweepstakesCardComponent',
  props: {
    sweepstakes: Object
  },
  methods: {
    navigateTo: function(path) {
      this.$router.push(path)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .container {
    border: solid 1px;
  }
</style>
